import { useEffect } from 'react';
import { useState } from 'react';
import {
  IGroupMemberLayoutProps,
  IPackageProps,
  IArtworkProps,
} from '../index';
import { Card } from '@merchstores/shared/elements/Card';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Gallery } from '@merchstores/shared/elements/Gallery';
import './styles.scss';
import { FormSelect } from '@merchstores/shared/elements/FormSelect';
import { useForm } from 'react-hook-form';
import Markdown from 'markdown-to-jsx';

import Modal from 'react-responsive-modal';
import { ShippingForm } from '@merchstores/admin/components/ShippingForm';
import { useHistory } from 'react-router-dom';
import { getLocalDateText } from '../../DateTime/DateTimeFormat';
import { Loading } from '@merchstores/shared/components/Loading';

import { MemberSubmissionManagedAddressForm } from '@merchstores/admin/components/GroupOrderMemberSubmission/MemberSubmissionManagedAddressForm';
import { ICustomFieldProps } from '../../CreateEditPackage';
import { FormInput } from '@merchstores/admin/shared/elements/FormInput';
import WelcomeModal from './WelcomeModal';
import SelectPackage from './SelectPackage';

export const GroupMemberPDP = (props: IGroupMemberLayoutProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState('');
  const [groupOrder, setGroupOrder] = useState({});
  const [selectedPackage, setSelectedPackage] = useState({});
  const [packageSizes, setPackageSizes] = useState([]);
  const [packageStyles, setPackageStyles] = useState([]);
  const [artworkOptions, setArtworkOptions] = useState([]);
  const [expandArtwork, setExpandArtwork] = useState(false);
  const [packageImages, setPackageImages] = useState([]);
  const [shippingFormOpen, setShippingFormOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [customFieldResponses, setCustomFieldResponses] = useState([]);
  const showMarketing =
    groupOrder.showMarketing !== undefined ? groupOrder.showMarketing : true; // Shows marketing by default if the field is not set (old orders)
  const history = useHistory();
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [welcomeImage, setWelcomeImage] = useState('');

  useEffect(() => {
    if (props.groupOrder !== groupOrder) {
      setGroupOrder(props.groupOrder);
    }
  }, [props.groupOrder]);

  useEffect(() => {
    if (groupOrder.packages && groupOrder.packages.length > 0) {
      selectPackage(groupOrder.packages[0].title);
      setPackageImages(groupOrder.packages[0].images);
      setLoading(false);
      setErrorText('');
      setWelcomeModalOpen(groupOrder.showWelcomeMessage);
      setWelcomeMessage(groupOrder.welcomeMessage);
      setWelcomeImage(groupOrder.welcomeImage);
    } else {
      setErrorText('This Group order has no packages.');
    }
  }, [groupOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  function selectPackage(packageTitle: string) {
    const selectedPackage = groupOrder.packages.find(
      (p: IPackageProps) => p.title === packageTitle
    ) as IPackageProps;
    if (!selectedPackage) {
      return;
    }
    setSelectedPackage(selectedPackage);
    setPackageSizes([]);
    setPackageStyles([]);
    setArtworkOptions([]);
    setPackageImages([]);

    if (selectedPackage.sizes) {
      let options = selectedPackage.sizes.split(',');
      options = options.map((o) => o.trim());
      setPackageSizes(options);
      if (options.length > 0) {
        setSelectedSize(options[0]);
      }
    }

    if (selectedPackage.styleRequired && selectedPackage.styles) {
      let styles = selectedPackage.styles.split(',');
      styles = styles.map((s) => s.trim());
      setPackageStyles(styles);
      if (styles.length > 0) {
        setSelectedStyle(styles[0]);
      }
    }

    if (selectedPackage.artwork && selectedPackage.artwork.length > 0) {
      const artwork = selectedPackage.artwork;
      setArtworkOptions(artwork);
      setSelectedArtwork(artwork[0].logo);
    }

    if (selectedPackage.images && selectedPackage.images.length > 0) {
      setPackageImages(selectedPackage.images);
    }
  }

  const onFormSubmitted = () => {
    history.push({
      pathname: '/thank-you-page',
      state: { groupOrder },
    });
  };

  if (errorText !== '') return <p>{errorText}</p>;
  if (loading) return <Loading isLoading={true} />;

  return (
    <>
      <WelcomeModal
        open={welcomeModalOpen}
        welcomeMessage={welcomeMessage}
        welcomeImage={welcomeImage}
        orderId={groupOrder.groupOrderId}
      />
      <div className="relative group-member-page-pdp-container">
        <div className="flex items-center justify-center flex-col mt-4 mb-8 px-4 max-w-4xl">
          <h1 className="text-center font-bold">
            <Markdown>{groupOrder.storeMessage || ''}</Markdown>
          </h1>
          <p className="text-center">
            <Markdown>{groupOrder.storeSubMessage || ''}</Markdown>
          </p>
        </div>
        {groupOrder.packages && groupOrder.packages.length > 0 && (
          <SelectPackage
            packages={groupOrder.packages}
            selectedPackage={selectedPackage}
            selectPackage={(packTitle: string) => selectPackage(packTitle)}
          />
        )}
        <div
          className={`group-member-page-details-container ${
            expandArtwork ? 'expanded' : ''
          }`}
        >
          <Card>
            <div
              className={`group-member-page-details flex flex-col md:flex-row`}
            >
              <div
                className={`order-artwork ${expandArtwork ? 'expanded' : ''}`}
              >
                <Gallery
                  images={packageImages.map((a: any) => a.url)}
                  hasThumbnails={true}
                  expandFunction={() => setExpandArtwork(true)}
                  closeExpandFunction={() => setExpandArtwork(false)}
                  selectedPackage={selectedPackage}
                />
              </div>
              <div className="order-details flex flex-col bg-merch-light-gray bg-opacity-40 w-full h-full">
                {selectedPackage && (
                  <div className="text-left text-2xl font-bold mb-4">
                    {selectedPackage.title}
                  </div>
                )}
                {selectedPackage.description && (
                  <div className="form-row">
                    <label className="font-bold">Package description:</label>
                    <p className="whitespace-pre-wrap">
                      {selectedPackage.description}
                    </p>
                  </div>
                )}
                {packageSizes && packageSizes.length > 0 && (
                  <div className="mb-2">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="package_sizes"
                      label="Select your size:"
                      options={packageSizes.map((option: string) => {
                        return { value: option, displayText: option };
                      })}
                      default={packageSizes[0]}
                      onChange={(option) => setSelectedSize(option.value)}
                      disabled={groupOrder.status === 'closed'}
                    />
                  </div>
                )}
                {packageStyles && packageStyles.length > 0 && (
                  <div className="mb-2">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="package_styles"
                      label="Select your style:"
                      options={packageStyles.map((option: string) => {
                        return { value: option, displayText: option };
                      })}
                      default={packageStyles[0]}
                      onChange={(option) => setSelectedStyle(option.value)}
                      disabled={groupOrder.status === 'closed'}
                    />
                  </div>
                )}
                {artworkOptions && artworkOptions.length > 1 && (
                  <div className="mb-4">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="artwork-options"
                      label="Select your artwork:"
                      options={artworkOptions.map((artwork: IArtworkProps) => {
                        const splitted = artwork.logo.split('/');
                        const fileName = splitted[splitted.length - 1];
                        return { value: artwork.logo, displayText: fileName };
                      })}
                      artwork={true}
                      default={artworkOptions[0].logo}
                      onChange={(option) => setSelectedArtwork(option.value)}
                      disabled={groupOrder.status === 'closed'}
                    />
                  </div>
                )}
                {selectedPackage.showCustomFields &&
                  selectedPackage.customFields.map(
                    (field: ICustomFieldProps) => (
                      <div className="mb-4" key={field.fieldName}>
                        {field.isDropdown ? (
                          <FormSelect
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            name={`custom-field-${field.fieldName}`}
                            label={field.fieldName}
                            options={field.dropdownOptions
                              .split(',')
                              .map((option: string) => {
                                return {
                                  value: option,
                                  displayText: option,
                                };
                              })}
                            default={field.dropdownOptions.split(',')[0]}
                            onChange={(option) =>
                              setCustomFieldResponses({
                                ...customFieldResponses,
                                [field.fieldName]: option.value,
                              })
                            }
                            disabled={groupOrder.status === 'closed'}
                          />
                        ) : (
                          <>
                            <div className="text-sm text-merch-dark-gray font-bold mb-2">
                              {field.fieldName}
                            </div>
                            <FormInput
                              register={register}
                              errors={errors}
                              name={`custom-field-${field.fieldName}`}
                              className="mb-4"
                              disabled={groupOrder.status === 'closed'}
                              type="text"
                              required={false}
                              onChange={(e) =>
                                setCustomFieldResponses({
                                  ...customFieldResponses,
                                  [field.fieldName]: e.target.value,
                                })
                              }
                            />
                          </>
                        )}
                      </div>
                    )
                  )}
                <CTA
                  type="primary"
                  size="standard"
                  classes="text-sm"
                  onClick={() => setShippingFormOpen(true)}
                  disabled={groupOrder.status === 'closed'}
                >
                  Proceed to Shipping Details
                </CTA>
                {groupOrder.status === 'closed' ? (
                  <div className="flex flex-col items-center mt-5">
                    <p>This group order is closed.</p>
                  </div>
                ) : (
                  <>
                    {groupOrder.closeDate && (
                      <div className="flex flex-col items-center mt-5">
                        <p>This group order closes on:</p>
                        <p className="text-lg font-bold">
                          {getLocalDateText(groupOrder.closeDate)}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <Modal
                open={shippingFormOpen}
                onClose={() => setShippingFormOpen(false)}
                center
                classNames={{ modal: 'shipping-form-modal' }}
              >
                {/* HERE:SOL-158 */}
                {groupOrder && groupOrder.managedShippingAddresses ? (
                  <MemberSubmissionManagedAddressForm
                    order={groupOrder}
                    selectedPackage={{
                      ...selectedPackage,
                      selectedSize,
                      selectedArtwork,
                      selectedStyle,
                      customFieldResponses,
                    }}
                    onCloseModal={() => setShippingFormOpen(false)}
                    onFormSubmitted={onFormSubmitted}
                    readOnly={false}
                  />
                ) : (
                  <ShippingForm
                    selectedPackage={{
                      ...selectedPackage,
                      selectedSize,
                      selectedArtwork,
                      selectedStyle,
                      customFieldResponses,
                    }}
                    order={groupOrder}
                    onCloseModal={() => setShippingFormOpen(false)}
                    onFormSubmitted={onFormSubmitted}
                    title="Your Group Order Details"
                    showMarketing={showMarketing}
                    readOnly={false}
                  />
                )}
              </Modal>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
