import Modal from 'react-responsive-modal';
import { useEffect } from 'react';
import { useState } from 'react';

import {
  IMerchStore,
  IMerchStoreContactInfo,
} from '@merchstores/admin/components/MerchStore/Merchstore';
import { ContactInfoForm } from './ContactInfoForm';

import './ContactInfoModal.scss';

const SupportMailLink = (props: {
  children: string | HTMLElement | JSX.Element;
}): JSX.Element => {
  return (
    <a
      className="link-2"
      href="mailto:sales@merchology.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};

export interface IContactInfoModalProps {
  modalOpen: boolean;
  onCancel?: () => void;
  onSave?: (contactInfo: IMerchStoreContactInfo) => void;
  merchStore?: IMerchStore;
}
export const ContactInfoModal = (
  props: IContactInfoModalProps
): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(props.modalOpen);
  const [merchStore, setMerchStore] = useState(props.merchStore);

  useEffect(() => {
    if (props.modalOpen !== modalOpen) {
      setModalOpen(props.modalOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalOpen]);

  useEffect(() => {
    if (props.merchStore && props.merchStore !== merchStore) {
      setMerchStore(props.merchStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.merchStore]);

  const onCloseModal = () => {
    setModalOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const onSave = (contactInfo: IMerchStoreContactInfo) => {
    if (props.onSave) {
      props.onSave(contactInfo);
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        classNames={{ modal: 'contact-info-modal' }}
        onClose={onCloseModal}
        center
      >
        <h2>Contact Information</h2>
        <h3 className="">
          Contact information is required to set up your MerchStore. Contact
          address can be different than shipping address. If you have any
          further questions please contact&nbsp;
          <SupportMailLink>customer support</SupportMailLink> for help.
        </h3>
        <ContactInfoForm
          contactInfo={
            merchStore && merchStore.contactInfo ? merchStore.contactInfo : null
          }
          onCancelAction={onCloseModal}
          onSubmitAction={onSave}
        />
      </Modal>
    </>
  );
};
