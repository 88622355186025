import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Checkbox } from '@merchstores/admin/shared/elements/Checkbox';

const ONE_HOUR = 60 * 60 * 1000; // One hour in milliseconds
const LAST_CLOSED_KEY = 'welcomeModalLastClosed';

export default function WelcomeModal({
  open = false,
  welcomeMessage,
  welcomeImage,
  orderId,
}: {
  open?: boolean;
  welcomeMessage?: string;
  welcomeImage?: string;
  orderId?: string;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(() => {
    const lastClosed = localStorage.getItem(`${LAST_CLOSED_KEY}-${orderId}`);
    const now = new Date().getTime();
    return open && (!lastClosed || now - parseInt(lastClosed, 10) > ONE_HOUR);
  });
  const [dontShowAgain, setDontShowAgain] = useState(true);

  function onClose() {
    setIsOpen(false);
    if (dontShowAgain) {
      localStorage.setItem(
        `${LAST_CLOSED_KEY}-${orderId}`,
        new Date().getTime().toString()
      );
    }
  }

  return (
    <Modal
      open={isOpen}
      center
      styles={{
        modal: {
          width: '80%',
          maxHeight: '80%',
          borderRadius: '16px',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      onClose={onClose}
    >
      {welcomeImage && (
        <img
          src={welcomeImage}
          alt="Welcome"
          style={{
            width: '100%',
            objectFit: 'cover',
            maxWidth: '800px',
            borderRadius: '8px',
          }}
        />
      )}
      <div className="text-left mt-8">
        {welcomeMessage || welcomeImage
          ? welcomeMessage
          : 'No welcome message to show.'}
      </div>
      <div className="flex items-center mt-8 flex-col">
        <CTA
          type="secondary"
          size="standard"
          classes="text-sm border-blue-700"
          onClick={onClose}
        >
          Order Now
        </CTA>
        <Checkbox
          label="Don't show this message again"
          className="items-center mt-4"
          onChange={setDontShowAgain}
          isSelected={dontShowAgain}
        />
      </div>
    </Modal>
  );
}
