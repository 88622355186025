import { PayerType } from './merchstoreOrder';

export enum MerchStoreTab {
  MEMBERS = 'members',
  PRODUCTS = 'products',
  ORDERS = 'orders',
  GIFT_CARS = 'giftCards',
}

interface IMerchStoreContactInfo {
  email: string;
  firstName: string;
  lastName: string;
  company?: string;
  address1: string;
  address2?: string;
  city: string;
  province: string;
  country: string;
  zip: string;
  phone?: string;
}

interface IMerchStoreOfficeAddress {
  firstName: string;
  lastName: string;
  company?: string;
  address1: string;
  address2?: string;
  city: string;
  province: string;
  country: string;
  zip: string;
  phone?: string;
}

interface IMerchStoreGiftCardsStatus {
  enabled: boolean;
  currentCardValue: string;
  currentCardVariantId: string | null;
  remainingCards: number;
  hasPreviousGiftCardValue: boolean;
  assignFromMemberListOnly: boolean;
  assignToMemberListFromStore: boolean;
  assignToNonMemberFromStore: boolean;
  preApprovedPaymentMethod: boolean;
  preApprovedPaymentMethodLongTerm?: boolean;
}

interface IMerchStore {
  name?: string;
  baseCollectionHandle?: string;
  storeCode: string;
  nextProcessingDate?: string;
  shipToOffice?: boolean;
  baggedLabeled?: boolean;
  officeAddress?: IMerchStoreOfficeAddress;
  contactInfo?: IMerchStoreContactInfo;
  status: string;
  customerAdmins?: Array<string>;
  subdomain: string;
  storeLogo?: string;
  bannerImage?: string;
  storeMessage?: string;
  revenue?: number;
  accentColor?: string;
  merchologist?: string;
  payerType?: PayerType;
  alwaysOpen?: boolean;
  processingSchedule?: string;
  artworkOptions?: Array<string>;
  netlifySiteId: string;
  customDomain?: string;
  chargeShipping?: boolean;
  autoProcessOrders?: boolean;
  giftCardsStatus?: IMerchStoreGiftCardsStatus;
}

export type {
  IMerchStoreGiftCardsStatus,
  IMerchStoreOfficeAddress,
  IMerchStoreContactInfo,
  IMerchStore,
};
