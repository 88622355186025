import { toast } from 'react-toastify';
import { useState } from 'react';
import { fetchIndividualOrders } from '@merchstores/admin/components/MerchStoreOrders';
import { ICombinedOrder } from '@merchstores/admin/types/shopifyCombinedOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';

import { PayerType } from '@merchstores/shared/components/MerchStore';

import {
  buildCartFields,
  buildCheckoutFields,
  combineOrdersItems,
  requestCheckoutUrl,
} from '@merchstores/admin/components/MerchStoreOrderCheckout';
import { CTA } from '@merchstores/shared/elements/Cta';
import { chargeShippingUnlessExplicitFalse } from '@merchstores/shared/components/Shipping';

import { SHOPIFY_MEMBER_SUBMISSION_VARIANT_GID } from '@merchstores/admin/config/MerchStoreOrderCheckout';

export interface IOrderCheckoutCTAProps {
  disabled?: boolean;
  display?: boolean;
  merchStore: IMerchStore;
  combinedOrder: ICombinedOrder;
}

export const OrderCheckoutCTA: React.FC<IOrderCheckoutCTAProps> = (
  props: IOrderCheckoutCTAProps
) => {
  const [waitForSubmit, setWaitForSubmit] = useState(false);
  const [loadedOrdersCount, setLoadedOrdersCount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const merchStore = props.merchStore;
  const combinedOrder = props.combinedOrder;

  const handleProceedToCheckout = async () => {
    setWaitForSubmit(true);

    const individualOrdersData = await fetchIndividualOrders(
      merchStore.storeCode,
      combinedOrder.id
    );
    const individualOrders = individualOrdersData.orders;
    const totalIndividualOrders = individualOrders.length;

    setTotalOrdersCount(totalIndividualOrders);

    let loadedOrdersCount = 0;

    const orderItems = await combineOrdersItems(merchStore, individualOrders, {
      onIndividualOrder: () => {
        loadedOrdersCount++;
        setLoadedOrdersCount(loadedOrdersCount);
      },
    });

    const isAdminPayer = merchStore.payerType === PayerType.ADMIN;
    // merchStore.shipToOffice in some cases may be a string value
    const isIndividualShipping = String(merchStore.shipToOffice) !== 'true';
    const chargeShipping = chargeShippingUnlessExplicitFalse(
      merchStore.chargeShipping
    );

    if (isAdminPayer && isIndividualShipping && chargeShipping) {
      // SOL-52
      const totalNonEmptyIndividualOrders = individualOrders.filter(
        (order) => order.itemsCount && order.itemsCount > 0
      ).length;

      orderItems.push({
        variantId: SHOPIFY_MEMBER_SUBMISSION_VARIANT_GID,
        quantity: totalNonEmptyIndividualOrders,
      });
    }

    const cartFields = buildCartFields(orderItems, merchStore);

    try {
      const checkoutResponse = await requestCheckoutUrl(cartFields);

      if (checkoutResponse.errors.length) {
        // If the checkout fails due to an address error, notify the user
        delete cartFields.buyerIdentity;
        const testNoAddressCheckoutResponse = await requestCheckoutUrl(
          cartFields
        );

        console.error(`Errors found, trying to clean shipping address`);

        if (!testNoAddressCheckoutResponse.errors.length) {
          console.error(checkoutResponse.errors);
          toast.error(
            `Your Office Address is incomplete, please fill in the missing fields and try again.`
          );
        } else {
          console.error(testNoAddressCheckoutResponse.errors);
          toast.error(
            `Error starting your checkout process, please contact our support.`
          );
        }
      }

      if (checkoutResponse.checkoutUrl) {
        window.location.assign(checkoutResponse.checkoutUrl);
      }
    } catch (err) {
      toast.error(
        `Error starting your checkout process, please contact our support.`
      );
      console.error(err);
    }

    setWaitForSubmit(false);
  };

  if (props.display === false) {
    return null;
  }

  return (
    <div className="cart-checkout-cta w-full">
      <CTA
        type="primary"
        size="standard"
        classes="text-sm w-full"
        onClick={handleProceedToCheckout}
        disabled={props.disabled || waitForSubmit}
      >
        {waitForSubmit && loadedOrdersCount
          ? `Loading ${loadedOrdersCount} / ${totalOrdersCount}`
          : waitForSubmit
          ? `Loading...`
          : 'Submit Order'}
      </CTA>
    </div>
  );
};
