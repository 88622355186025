import {
  IShopifyLineItem,
  IShopifyProductVariant,
} from '@merchstores/admin/types/merchstoreOrder';

export const OPTION_SIZE = 'Size';
export const ATTRIBUTE_DECORATION_TYPE = '_Decoration type';
export const ATTRIBUTE_DECORATION_LOCATION = '_Select decoration location';
export const ATTRIBUTE_ARTWORK = '_Upload your logo (2MB max)';
export const ATTRIBUTE_LOGO_MOCKUP = '_Logo Mockup Image';
export const ATTRIBUTE_PAYER_TYPE = '_payer_type';
export const ATTRIBUTE_ORDER_TYPE = '_order_type';
export const ATTRIBUTE_SHIP_TO_OFFICE = '_ship_to_office';
export const ATTRIBUTE_BAGGED_LABELED = '_bagged_labeled';
export const ATTRIBUTE_CHARGE_SHIPPING = '_charge_shipping';

export function createVariantOptionsMap(
  productVariant: IShopifyProductVariant
) {
  const options = new Map<string, string>();

  productVariant.selectedOptions.forEach((attr) => {
    options.set(attr.name, attr.value);
  });

  return options;
}

export function createLineItemAttributesMap(lineItem: IShopifyLineItem) {
  const attributes = new Map<string, string>();

  lineItem.customAttributes.forEach((attr) => {
    const key = attr.key.startsWith('_') ? attr.key : `_${attr.key}`;
    attributes.set(key, attr.value);
  });

  return attributes;
}

export function mapLineItemToOrderItem(lineItem: IShopifyLineItem) {
  if (!lineItem.variant) {
    throw new Error(
      'Variant for line item ' +
        JSON.stringify(lineItem) +
        ' is missing in MerchStore '
    );
  }

  const attributesMap = createLineItemAttributesMap(lineItem);
  const optionsMap = createVariantOptionsMap(lineItem.variant);

  const orderItem = {
    variantId: lineItem.variant.id,
    quantity: lineItem.quantity,
    size: optionsMap.get(OPTION_SIZE),
    artwork: attributesMap.get(ATTRIBUTE_ARTWORK),
    logoMockup: attributesMap.get(ATTRIBUTE_LOGO_MOCKUP),
    decorationType: attributesMap.get(ATTRIBUTE_DECORATION_TYPE),
    decorationLocation: attributesMap.get(ATTRIBUTE_DECORATION_LOCATION),
  };

  return orderItem;
}
