export interface IMailingAddressInput {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  company?: string;
  zip: string;
  province: string;
  country: string;
  phone?: string;
}

export interface ICustomAttribute {
  key: string;
  value: string;
}

export interface ILine {
  merchandiseId: string;
  quantity: number;
  attributes: Array<ICustomAttribute>;
}

export interface ILineItem {
  variantId: string;
  quantity: number;
  customAttributes: Array<ICustomAttribute>;
}

export interface ICartCreateInput {
  lines: Array<ILine>;
  attributes: Array<ICustomAttribute>;
  buyerIdentity?: IBuyerIdentity;
  deliveryAddressPreferences?: {
    deliveryAddress?: IMailingAddressInput;
  };
}

export interface IBuyerIdentity {
  email?: string;
}

export interface IDeliveryAddress {
  deliveryAddress: {
    firstName: string;
    lastName: string;
    address1: string;
    address2?: string;
    city: string;
    company?: string;
    zip: string;
    provinceCode: string;
    countryCode: string;
    phone?: string;
  };
}

export interface IDeliveryAddressEntry {
  address: IDeliveryAddress;
  validationStrategy: string;
}

export interface ICartFields {
  lines: Array<ILine>;
  attributes: Array<ICustomAttribute>;
  buyerIdentity?: IBuyerIdentity;
  delivery?: {
    addresses: Array<IDeliveryAddressEntry>;
  };
}

export function makeCartCreateMutation(cartCreateInput: ICartCreateInput): {
  query: string;
  variables: { input: ICartCreateInput };
} {
  console.log('cartCreateInput', cartCreateInput);

  const query = `
    mutation cartCreate($input: CartInput!) {
      cartCreate(input: $input) {
        cart {
          id
        }
        userErrors {
          code
          field
          message
        }
      } 
    }
  `;
  return {
    query: query,
    variables: {
      input: cartCreateInput,
    },
  };
}

export function makeCartCheckoutURLQuery(cartId: string): {
  query: string;
} {
  const query = `
    query checkoutUrl {
      cart(id: "${cartId}") {
        checkoutUrl
      }
    }
  `;
  return {
    query,
  };
}
