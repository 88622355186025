import { CTA } from '@merchstores/admin/shared/elements/Cta';
import { Input } from '@merchstores/admin/shared/elements/Input';
import { Checkbox } from '@merchstores/admin/shared/elements/Checkbox';

function DropdownField({
  field,
  index,
  updateField,
  deleteField,
}: any): JSX.Element {
  const isDropdown = field.isDropdown;

  return (
    <div className="flex flex-row">
      {isDropdown && (
        <div className="flex w-4 justify-center items-center">
          <div className="w-4 h-1/2 border-2 border-r-0 border-gray-300"></div>
        </div>
      )}
      <div className="flex flex-col w-full">
        <div className="flex flex-row">
          <Input
            label={`${index + 1}. Field Name`}
            name="field-name"
            type="text"
            required={true}
            errors={[]}
            tooltip={
              'Enter the name of the field. ' +
              'This will label the input field on the order storefront.'
            }
            inputStyle="two"
            value={field.fieldName}
            onChange={(e) => updateField(index, 'fieldName', e.target.value)}
          />
          <div className="flex flex-row absolute right-4">
            <Checkbox
              label="Dropdown Options"
              className=""
              isSelected={isDropdown}
              onChange={() => updateField(index, 'isDropdown', !isDropdown)}
            />
            <CTA
              size={'standard'}
              type={'secondary'}
              classes="h-4"
              onClick={() => deleteField(index)}
            >
              Delete
            </CTA>
          </div>
        </div>
        {isDropdown && (
          <Input
            label="Dropdown Options"
            name="dropdown-options"
            type="text"
            required={true}
            errors={[]}
            inputStyle="two"
            value={field.dropdownOptions}
            tooltip={
              'Enter the options for the dropdown field. ' +
              'Each option should be separated by a comma.'
            }
            onChange={(e) =>
              updateField(index, 'dropdownOptions', e.target.value)
            }
          />
        )}
      </div>
    </div>
  );
}

function safeText(value: string) {
  return value.replace(/'/g, '').replace(/"/g, '');
}

type CustomFieldsProps = {
  customFields: any[];
  setCustomFields: (customFields: any[]) => void;
};
export default function CustomFields({
  customFields,
  setCustomFields,
}: CustomFieldsProps): JSX.Element {
  function updateField(index: number, key: string, value: any) {
    const formattedKey = safeText(key);
    const formattedValue = typeof value === 'string' ? safeText(value) : value;
    const updatedFields = [...customFields];
    updatedFields[index] = {
      ...updatedFields[index],
      [formattedKey]: formattedValue,
    };
    setCustomFields(updatedFields);
  }

  function deleteField(index: number) {
    console.log('deleteField', index);
    setCustomFields(customFields.filter((_: any, i: number) => i !== index));
    console.log('customFields', customFields);
  }

  return (
    <div className="mb-4">
      {customFields.map(
        (field: any, index: number): JSX.Element => (
          <DropdownField
            key={index}
            field={field}
            index={index}
            updateField={updateField}
            deleteField={deleteField}
          />
        )
      )}
      <CTA
        size={'standard'}
        type={'secondary'}
        classes="text-14"
        onClick={() =>
          setCustomFields([
            ...customFields,
            { fieldName: '', dropdownOptions: '', isDropdown: false },
          ])
        }
      >
        Add Custom Field
      </CTA>
    </div>
  );
}
