import { DateTime } from 'luxon';
import * as storefrontQuery from '@merchstores/admin/queries/storefront-graphql';
import { buildLineItemAttributes } from '@merchstores/admin/components/MerchStoreOrderCheckout';
import { resolveMerchStoreDomain } from '@merchstores/admin/components/MerchStore/Domain';
import { resolveMerchstoreUrl } from '@merchstores/admin/components/MerchStore/Url';
import { customAttr } from '@merchstores/admin/components/MerchStoreOrderCheckout';
import { IOrderItem } from '@merchstores/admin/types/merchstoreOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { merchstoreStorefrontClient } from '@merchstores/admin/App';
import {
  ICartFields,
  makeCartCreateMutation,
  makeCartCheckoutURLQuery,
} from '@merchstores/admin/queries/storefront-checkout-2025-01';
import {
  OrderType,
  PayerType,
} from '@merchstores/shared/components/MerchStore';
import {
  ICheckoutUserError,
  ICustomAttribute,
} from '@merchstores/admin/types/shopifyCheckout';
import { createStorefrontApiClient } from '@merchstores/admin/components/ShopifyStorefront';

import { PaymentMethod } from '@merchstores/admin/types/merchstoreOrder';

import { IMerchStoreGiftCardsStatus } from '@merchstores/admin/types/merchstore';
import { getCountryIsoFromName } from '@merchstores/admin/components/ISOCountries';

const SHOPIFY_CHECKOUT_DOMAIN = process.env.REACT_APP_SHOPIFY_CHECKOUT_DOMAIN;

export interface IGiftCardSelection {
  giftCardQuantity: number;
  giftCardValue: string;
}

export function buildCartFields(
  orderItems: Array<IOrderItem>,
  merchstore: IMerchStore,
  giftCardsStatus: IMerchStoreGiftCardsStatus,
  cardSelection: IGiftCardSelection
): ICartFields {
  const storeDomain = resolveMerchStoreDomain(merchstore);

  const storeUrl = resolveMerchstoreUrl(merchstore);

  const baseLineItemAttributes: Array<ICustomAttribute> = [];

  const paymentMethods: Array<PaymentMethod> = [PaymentMethod.CREDIT_CARD];

  if (
    giftCardsStatus?.preApprovedPaymentMethod ||
    giftCardsStatus?.preApprovedPaymentMethodLongTerm
  ) {
    paymentMethods.push(PaymentMethod.PRE_APPROVED_OTHER);
  }

  const lineItems = orderItems.map((item) => {
    const lineItemAttributes = baseLineItemAttributes.concat(
      buildLineItemAttributes(item /*, merchstore*/)
    );

    const isGid = /gid:/.test(item.variantId);
    const variantGid = isGid
      ? item.variantId
      : `gid://shopify/ProductVariant/${item.variantId}`;

    const encodedVariantId = Buffer.from(variantGid).toString('base64');

    return {
      merchandiseId: encodedVariantId,
      quantity: item.quantity,
      attributes: lineItemAttributes,
    };
  });

  const checkoutCustomizationAttributes = [
    customAttr('payer_type', PayerType.INDIVIDUAL),
    customAttr('order_type', OrderType.GIFT_CARD),
    customAttr(
      'ship_to_office',
      String(String(merchstore.shipToOffice) === 'true')
    ),
    customAttr('bagged_labeled', 'false'),
    /* Giftcards must not have shipping */
    customAttr('charge_shipping', 'false'),
    customAttr('gift_card_quantity', String(cardSelection.giftCardQuantity)),
    customAttr('gift_card_value', String(cardSelection.giftCardValue)),
    customAttr('payment_methods', paymentMethods.map(String).join(',')),
  ];

  if (lineItems.length) {
    lineItems[0].attributes = lineItems[0].attributes.concat(
      checkoutCustomizationAttributes.map((attr) => {
        return {
          key: `_${attr.key}`,
          value: attr.value,
        };
      })
    );
  }

  if (lineItems.length > 1) {
    const lastElem = lineItems.length - 1;
    lineItems[lastElem].attributes = lineItems[lastElem].attributes.concat(
      checkoutCustomizationAttributes
    );
  }

  const closeDate = DateTime.now().toISODate();

  const cartAttributes: Array<ICustomAttribute> = [
    customAttr('subdomain', merchstore.subdomain),
    customAttr('merchstore_domain', storeDomain),
    customAttr('merchstore_url', storeUrl),
    customAttr('merchstore_code', merchstore.storeCode + ''),
    customAttr('payer_type', PayerType.INDIVIDUAL),
    customAttr('order_type', OrderType.GIFT_CARD),
    customAttr('ship_to_office', 'false'),
    customAttr('bagged_labeled', 'false'),
    customAttr('merchstore_logo', merchstore.storeLogo + ''),
    /* Giftcards must not have shipping */
    customAttr('charge_shipping', 'false'),
    customAttr('close_date', closeDate),
    customAttr('gift_card_quantity', String(cardSelection.giftCardQuantity)),
    customAttr('gift_card_value', String(cardSelection.giftCardValue)),
    customAttr('payment_methods', paymentMethods.map(String).join(',')),
  ].filter((attr) => attr.value && typeof attr.value !== 'undefined');

  const cartFields: ICartFields = {
    lines: lineItems,
    attributes: cartAttributes,
  };

  if (merchstore.shipToOffice && merchstore.officeAddress) {
    cartFields.delivery = {
      addresses: [
        {
          address: {
            deliveryAddress: {
              company: merchstore.officeAddress.company,
              firstName: merchstore.officeAddress.firstName,
              lastName: merchstore.officeAddress.lastName,
              address1: merchstore.officeAddress.address1,
              address2: merchstore.officeAddress.address2,
              city: merchstore.officeAddress.city,
              zip: merchstore.officeAddress.zip,
              provinceCode: merchstore.officeAddress.province,
              countryCode: getCountryIsoFromName(
                merchstore.officeAddress.country
              ),
              phone: merchstore.officeAddress.phone,
            },
          },
          validationStrategy: 'STRICT',
        },
      ],
    };
  }

  return cartFields;
}

export interface ICheckoutStatus {
  checkoutUrl: string;
  errors: Array<ICheckoutUserError>;
}

export async function requestCheckoutUrl(
  cartFields: ICartFields
): Promise<ICheckoutStatus> {
  const storefrontClient = createStorefrontApiClient('2025-01');

  const cartCreateMutation = makeCartCreateMutation(cartFields);

  const createResponse = await storefrontClient.post('', cartCreateMutation);

  const createData = createResponse.data.cartCreate;

  const createErrors = createData.userErrors;

  if (createErrors.length) {
    throw new Error(JSON.stringify(createErrors));
  }

  // const errors = await extractCheckoutErrors(createErrors, checkoutFields);

  // Shopify no longer returns an errors object. If the cart is empty and the userErrors object is empty, throw an error.
  if (!createData.cart && !createData.userErrors.length) {
    throw new Error(JSON.stringify('ERROR CREATING CART', createData));
  }

  let checkoutUrl = '';

  if (createData.cart) {
    const cartData = createData.cart;
    const cartId = cartData.id || '';

    const checkoutUrlQuery = makeCartCheckoutURLQuery(cartId);

    const checkoutUrlResponse = await storefrontClient.post(
      '',
      checkoutUrlQuery
    );

    const checkoutUrlResponseData = checkoutUrlResponse.data;

    checkoutUrl = checkoutUrlResponseData
      ? checkoutUrlResponseData.cart?.checkoutUrl
      : '';
  }

  if (SHOPIFY_CHECKOUT_DOMAIN) {
    checkoutUrl = checkoutUrl.replace(
      /https:\/\/[^.]+\.myshopify\.com\//,
      `https://${SHOPIFY_CHECKOUT_DOMAIN}/`
    );
  }

  return {
    checkoutUrl: checkoutUrl,
    errors: createErrors,
  };
}
