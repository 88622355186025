import axios, { AxiosRequestConfig } from 'axios';

const SHOPIFY_MERCH_SERVICES_STOREFRONT_URL =
  process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_URL;

if (!SHOPIFY_MERCH_SERVICES_STOREFRONT_URL) {
  throw new Error(
    `Missing ENV variable: REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_URL`
  );
}

const SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN =
  process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN;

if (!SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN) {
  throw new Error(
    `Missing ENV variable: REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN`
  );
}

export function createStorefrontApiClient(version?: string) {
  const baseUrl = String(SHOPIFY_MERCH_SERVICES_STOREFRONT_URL)
    .replace(/\/api\/.*/gi, '')
    .replace(/\/*$/g, '');

  const apiClient = axios.create({
    baseURL: `${baseUrl}/api/${version ? version : '2021-10'}/graphql`,
  });

  apiClient.defaults.headers.common['Content-Type'] = 'application/json';
  apiClient.defaults.headers.common['Accept'] = 'application/json';
  apiClient.defaults.headers.common['X-Shopify-Storefront-Access-Token'] =
    SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN;

  apiClient.interceptors.response.use(
    // On Success
    function (response) {
      if (response && response.data && response.data.errors) {
        const shopifyError = new StorefrontApiError(
          'Storefront API Error: ' + JSON.stringify(response.data.errors),
          {
            errors: response.data.errors,
            request: response.config,
          }
        );
        throw shopifyError;
      }

      response.data = response.data.data;

      return response;
    },
    // On Error
    function (err) {
      return Promise.reject(err);
    }
  );

  return apiClient;
}

export type IStorefrontErrorList = Array<{ message: string }>;
export interface IStorefrontGraphQLQuery {
  query: string;
  variables?: unknown;
}

export class StorefrontApiError extends Error {
  protected request;
  protected errors;

  constructor(
    msg: string,
    data: { request: AxiosRequestConfig; errors: IStorefrontErrorList }
  ) {
    super(msg);
    data = data || {};
    this.request = data.request;
    this.errors = data.errors;
  }

  getErrors(): IStorefrontErrorList {
    return this.errors;
  }

  getRequest(): AxiosRequestConfig {
    return this.request;
  }

  getQuery(): IStorefrontGraphQLQuery {
    return JSON.parse(this.request.data);
  }

  getInfo() {
    return {
      errors: this.getErrors(),
      query: this.getQuery(),
      request: this.getRequest(),
    };
  }
}
