import { IPackageProps } from '../index';

function PackageOption({
  image,
  title,
  selected,
  selectPackage,
}: {
  image: URL;
  title: string;
  selected: boolean;
  selectPackage: (packTitle: string) => void;
}): JSX.Element {
  const containerClassName = `
    flex 
    flex-col 
    gap-2 
    w-48 
    h-56 
    border-4 
    border-gray-300 
    rounded-md 
    p-2 
    justify-center 
    items-center 
    overflow-hidden
    cursor-pointer
    bg-white ${selected ? 'border-merch-orange' : 'border-dashed'}`;
  return (
    <div className={containerClassName} onClick={() => selectPackage(title)}>
      <img
        src={image.toString()}
        alt={title}
        className="w-48 h-48 object-contain"
      />
      <div className="text-center text-sm font-bold">
        {title.length > 48 ? `${title.substring(0, 48).trim()}...` : title}
      </div>
    </div>
  );
}

export default function SelectPackage({
  packages,
  selectedPackage,
  selectPackage,
}: {
  packages: IPackageProps[];
  selectedPackage: IPackageProps;
  selectPackage: (packTitle: string) => void;
}): JSX.Element {
  return (
    <div className="flex flex-col gap-4 justify-center items-center mb-4 px-4">
      <div className="text-center text-sm font-bold">Select a Package</div>
      <div className="flex flex-wrap gap-4 justify-center items-center">
        {packages.map((pack) => {
          const images = pack?.images || [];
          return (
            <PackageOption
              key={pack.title}
              image={images[0]?.url || ''}
              title={pack.title}
              selected={pack.title === selectedPackage.title}
              selectPackage={selectPackage}
            />
          );
        })}
      </div>
    </div>
  );
}
